/* Background Image */
/* body, html {

  } */

  #signin-page {
    background-image: url(/images//wb-login-bg.jpg?80e16934a105cfde2678033693c5b2f1);
    /* background-position: center; */
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
}

.wb-login-title {
    font-family: 'Trebuchet MS';
    font-size: 16px;
    color: #1d6ec4;
}

.alpha50 {
    /* Fallback for web browsers that doesn't support RGBa */
    background: rgb(255, 255, 255);
    background: rgba(255, 255, 255, 0.8);
    /* For IE 5.5 - 7*/
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
    /* For IE 8*/
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";
}

.admin-page-form {
    width: 400px;
    border-radius: 0 !important;
    margin: 5% auto;
}

.admin-page-form .body-content {
    padding: 15px 20px;
    position: relative;
  }

.alpha50 {
    /* Fallback for web browsers that doesn't support RGBa */
    background: rgb(255, 255, 255);
    background: rgba(255, 255, 255, 0.8);
    /* For IE 5.5 - 7*/
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
    /* For IE 8*/
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";
}

.header-content {
    width: 100%;
}

.wb-frameLogo {
    background-image: url(/images/logo/wb-logo275x183.png?f3abbb1b062739744433108d9ffcb317);
    background-repeat: no-repeat;
    width: 140px;
    height: 85px;
    background-size: 140px;
    display: block;
    margin: auto;
    margin-bottom: 10px;
}

.body-content {
    margin-top: 30px;
}

.form-group {
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 15px;
    box-sizing: border-box;
}

.form-group.pull-right {
    float: right !important;
}

.input-icon {
    position: relative;
}

.input-icon i {
    color: #999999;
    display: block;
    position: absolute;
    margin: 10px 2px 4px 10px;
    width: 16px;
    height: 16px;
    font-size: 16px;
    text-align: center;
}

.input-icon.right i {
    top: 0;
    right: 8px;
    float: right;
}

.form-control {
    color: #999999;
    display: block;
    width: 85%;
    padding: 6px 12px;
    padding-right: 5px;
    padding-left: 12px;
    font-size: 14px;
    line-height: 1.42857143;
    background-color: #fff;
    /* border: none; */
}

.btn-login {
    float: right;
    padding: 10px;
    border: none;
    color: #ffffff;
    background-color: #5cb85c;
    border-color: #55b555;
    cursor: pointer;
}

